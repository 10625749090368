import NotificationService from '../services/notification'
import i18n from '@/i18n.js'
import router from '@/router'
export const notification = {
    namespaced: true,
    state: () => ({
        type_id: null
    }),
    mutations: {
        SET_TYPE_ID(state, data) {
            state.type_id = data
        }
    },
    actions: {
        // notification
        set_type_id({ commit, rootState }, value) {
            commit('SET_TYPE_ID', value)
            rootState.form.style_form.map((v) => {
                if (v.value_text == "custome_user") {
                    v.visible = true
                    v.value = ""
                } else if (v.value_text == "user_id") {
                    v.visible = false
                    v.value = []
                }

            });
        },
        custome_user({ state, rootState }, value) {
            console.log("custome user", value)
            rootState.card.loading = true
            if (value == true) {
                axios.get("general/reassign_form/get-user-of-type/" + state.type_id).then(
                    (response) => {
                        rootState.card.loading = false
                        rootState.form.style_form.map((v) => {
                            if (v.value_text == "user_id") {
                                v.visible = true
                                v.items = response.data
                                return v
                            }
                        });
                        console.log(response);
                    },
                    (error) => {
                        rootState.card.loading = false
                    })
            }
        },
        add_notification({ rootState }, data) {
            return NotificationService.add_notification(data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    router.push({
                        name: "NotificationPage",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                }
            );

        },

        // pagination section
        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page
            dispatch("getData", {})
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("getData", { pre_page, type: "pre_page" })
        },

        // filter section 
        filterData({ rootState, dispatch }) {
            console.log('filter')
            rootState.table.paginate.page = 1;
            dispatch("getData", {})
        },
        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", { reset: true })
        },

        getData({ rootState }, data) {
            // console.log(data)
            rootState.table.loading = true;
            const formData = new FormData();
            if (!data.hasOwnProperty('reset')) {

                rootState.form.filters.filter(function (filter) {
                    formData.append(filter.name, filter.value);
                });
                console.log('reset1')
            }
            if (data.hasOwnProperty('type') && data.type == "pre_page") {
                formData.append(
                    "paginate",
                    data.pre_page
                );
            } else {
                if (rootState.table.paginate.itemsPerPage != '')
                    formData.append(
                        "paginate",
                        rootState.table.paginate.itemsPerPage
                    );
            }
            return NotificationService.get_notifications(rootState.table.paginate.page, formData).then(
                (response) => {
                    console.log(response.data);
                    rootState.table.items = response.data.data.data
                    rootState.table.paginate = {
                        total: response.data.data.total,
                        itemsPerPage: parseInt(response.data.data.per_page),
                        row_pre_page: true,
                        page: rootState.table.paginate.page,
                    }
                    if (data.hasOwnProperty('reset') && data.reset) {
                        // console.log('reset2')
                        rootState.form.filters = response.data.filters;
                    }
                    rootState.table.loading = false;
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.table.loading = false;
                    if (error.response.status != 401) {

                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },

    },

};