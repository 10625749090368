import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";
export default {

    path: "/",
    component: DashboardLayout,
    name: "Notifications",
    children: [
        {
            path: "/notifications/create",
            name: "NewNotification",
            component: () => import("@/modules/notifications/views/New.vue"),
            meta: {
                groupName: "Notification",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "notification-create",
            },
        },

        {
            path: "/notifications",
            name: "NotificationPage",
            component: () => import("@/modules/notifications/views/Show.vue"),
            meta: {
                groupName: "Notification",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "notification-index",
            },
        },
    ],

}