export default {
    action: "fa fa-box",
    title: "LockOrders",
    permission: "lock",
    items: [
        {
            prefix: "O",
            title: "LockOrders",
            link: "/lock-orders",
            permission: "lock-index",
        },
        {
            prefix: "O",
            title: "AboutSignature",
            link: "/lock/about-signature",
            permission: "lock-signature",
        },

    ]
}